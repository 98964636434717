import HeroImage from "./Assets/HuleExchangeWide.svg"
import HugeLogo from "./Assets/HuleExchangeLogoOnly.svg"
import { motion } from "framer-motion"

function App() {

  const varients = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  }

  const svgVarients = {
    start: {
      opacity: 0,
      pathLength: 0
    },
    finished: {
      opacity: 1,
      pathLength: 1,
      transition: {
        duration: 2,
        ease: "easeInOut"
      }
    }
  }

  return (
    <div className="bg-Darkblue bg-opacity-10">
      {/* <iframe src='https://my.spline.design/untitled-9d09556334f3b33e45cb096967561133/' frameborder='0' className="fixed w-full h-full z-10 bg-Darkpurple"></iframe> */}
      <iframe src='https://my.spline.design/untitled-9d09556334f3b33e45cb096967561133/' frameborder='0' className="fixed w-full h-screen hidden lg:block z-10"></iframe>
      <div className="w-full h-screen overflow-hidden">
        <div className="grid grid-cols-1 lg:grid-cols-2 w-full h-full">
          <div className="flex mt-10 lg:items-center lg:mt-0 mx-10 md:justify-center md:mx-0">
            <div className="flex flex-col  md:w-7/12 space-y-7">
              <motion.img
                initial={{ x: '-100vh' }}
                animate={{ x: 0 }}
                transition={{ type: 'spring', stiffness: 50, delay: .4 }}
                src={HeroImage} alt="" />
              <motion.p
                initial={{ opacity: 0 }}
                animate="visible"
                transition={{ delay: 1 }}
                variants={varients}
                className="text-lg text-justify md:text-left md:text-xl font-Poppins font-semibold">
                Hule Exchange is a secure online platform for buying, selling, transferring, and storing cryptocurrency using local African fiat currencies.
              </motion.p>
              <div>
                <motion.p
                  initial={{ opacity: 0 }}
                  animate="visible"
                  transition={{ delay: 1.1 }}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  variants={varients}
                  className="p-3 md:p-4 px-6 bg-Darkpurple w-auto border text-center rounded-lg text-lg text-CreamWhite font-Poppins font-bold">Coming Soon</motion.p>
              </div>
              {/* <div className="md:hidden text-center">
                Coming Soon Text
              </div> */}
            </div>
          </div>
          <div className="relative hidden lg:block -z-20">
            <div className="absolute -top-56 3xl:-top-40 -right-40 3xl:-right-20 scale-50 3xl:scale-100">
              <motion.svg
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                width="550" viewBox="0 0 115 169" fill="none" xmlns="http://www.w3.org/2000/svg">
                <motion.path
                  variants={svgVarients}
                  initial="start"
                  animate="finished"
                  d="M79.3254 23.6538H29.1311C27.5833 23.6538 26.2238 24.6676 25.8017 26.1366L11.9028 74.5014C11.2765 76.6808 12.9359 78.8466 15.2322 78.8466H65.4265C66.9742 78.8466 68.3337 77.8327 68.7559 76.3638L96.0511 0.13116C96.6774 -2.04823 81.6216 23.6538 79.3254 23.6538Z" fill="url(#paint0_linear_4_183)" />
                <motion.path variants={svgVarients}
                  initial="start"
                  animate="finished" d="M99.5122 87.3815H49.3179C47.7702 87.3815 46.4107 88.3953 45.9885 89.8643L19.6023 168.057C18.976 170.237 33.1228 142.574 35.419 142.574H85.6134C87.1611 142.574 88.5206 141.56 88.9427 140.091L102.842 91.7267C103.468 89.5473 101.808 87.3815 99.5122 87.3815Z" fill="url(#paint1_linear_4_183)" />
                <motion.path variants={svgVarients}
                  initial="start"
                  animate="finished" d="M112.114 47.5517H84.6695C83.3665 47.5517 82.2255 48.4136 81.8855 49.6544L74.7928 75.5438C74.2974 77.3521 75.678 79.1311 77.5768 79.1311H104.785C106.079 79.1311 107.215 78.2808 107.563 77.0513L114.892 51.1619C115.405 49.3482 114.023 47.5517 112.114 47.5517Z" fill="url(#paint2_linear_4_183)" />
                <motion.path variants={svgVarients}
                  initial="start"
                  animate="finished" d="M37.4231 87.3815H9.97812C8.6752 87.3815 7.53412 88.2434 7.19417 89.4842L0.101495 115.374C-0.393916 117.182 0.986656 118.961 2.88544 118.961H30.094C31.388 118.961 32.5235 118.111 32.8716 116.881L40.2007 90.9918C40.7141 89.1781 39.332 87.3815 37.4231 87.3815Z" fill="url(#paint3_linear_4_183)" />
                <defs>
                  <linearGradient id="paint0_linear_4_183" x1="28.75" y1="24.3073" x2="109.773" y2="170.018" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#2F1D95" />
                    <stop offset="1" stop-color="#1E56A0" />
                  </linearGradient>
                  <linearGradient id="paint1_linear_4_183" x1="28.75" y1="24.3073" x2="109.773" y2="170.018" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#2F1D95" />
                    <stop offset="1" stop-color="#1E56A0" />
                  </linearGradient>
                  <linearGradient id="paint2_linear_4_183" x1="28.75" y1="24.3073" x2="109.773" y2="170.018" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#2F1D95" />
                    <stop offset="1" stop-color="#1E56A0" />
                  </linearGradient>
                  <linearGradient id="paint3_linear_4_183" x1="28.75" y1="24.3073" x2="109.773" y2="170.018" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#2F1D95" />
                    <stop offset="1" stop-color="#1E56A0" />
                  </linearGradient>
                </defs>
              </motion.svg>

            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default App;
